import { useState, useEffect } from 'react';
import fetchData from './api.js'

export default function Page(props) {
    const [data, setData] = useState(null);
    const splitOn = function (input, char, occurence) {
      const s1 = input.split(char, occurence).join(char);
      const s2 = input.substring(s1.length);
      return { s1, s2 };
    };
        useEffect(() => {
        let ignore = false;
        setData(null);
        fetchData(props.dept).then(result => {
          if (!ignore) {
            setData(result);
          }
        });
        return () => {
          ignore = true;
        };
      }, [props.dept]);
      const ret = 
      data ?
      <ul className='headlines'>
        {data.map(item => {
          const { s1, s2 } = splitOn(item.title, " ", 6);
          return (
            <li key={item.title} className='truncate'>
            <span className={props.visi?"":"hide"}>{item.typeAttributes.trending.numViewers} </span><a href={"https://www.cbc.ca/lite/story/"+item.sourceId}>{s1}</a> <span className={"continuation"}>{s2}</span>
            </li>
          )
        })}
        </ul>
      : null;
    return (
    <div className="page">
      { ret }
    </div>
  );
}
