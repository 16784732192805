import axios from 'axios';

export default async function fetchData(dept="trending-news") {
  const result = await axios(
    // 'https://www.cbc.ca/aggregate_api/v1/items?pageSize=5&page=1&type=&categorySlug=radio&minPubDate=2024-4-24&maxPubDate=2024-4-24',
    `https://www.cbc.ca/aggregate_api/v1/items?page=1&pageSize=25&lineupSlug=${dept}&categorySlug=empty-category`,
  );
  return new Promise(resolve => {
      resolve(result.data);
  })
}
