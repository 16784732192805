import { useState } from 'react';
import './App.css';
import Page from './Page';
import Toolbox from './Toolbox';

export default function App() {
  const [visi, setVisi] = useState(false);
  const [dept, setDept] = useState("trending-news");
  return (
    <div className="App">
      <header className="header">
        <h2>CBC Top News</h2><Toolbox visi={visi} setVisi={setVisi} dept={dept} setDept={setDept}></Toolbox>
      </header>
      <Page visi={visi} dept={dept}></Page>
    </div>
  );
}

