export default function Toolbox(props) {
    const toggleViewers = function () {
        props.setVisi(!props.visi);
    }
    const setNews = function () {
        props.setDept("trending-news");
    }
    const setToronto = function () {
        props.setDept("trending-toronto");
    }
    const setRadio = function () {
        props.setDept("trending-radio");
    }
    return (
    <div className="toolbox">
      <button onClick={setRadio}>Radio</button>
      <button onClick={setToronto}>Toronto</button>
      <button onClick={setNews}>News</button>&nbsp;
      <button onClick={toggleViewers}>Viewers</button>
    </div>
  );
}
